<template>
  <div v-if="invalidDate">
    {{ invalidDate }}
  </div>
  <div v-else>
    {{ workDuration }}
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    start: [Date, String],
    end: [Date, String]
  },
  computed: {
    invalidDate() {
      return [this.start, this.end].some(
        e =>
          !e ||
          !moment(e).locale(`${this.$t('notifications.language')}`).isValid
      )
        ? 'Invalid date'
        : '';
    },
    workDuration() {
      const setEndTime = new Date(this.end);
      setEndTime.setHours(0);
      setEndTime.setMinutes(0);
      setEndTime.setSeconds(0);

      const formatter = date =>
        moment(date)
          .locale(`${this.$t('notifications.language')}`)
          .format('DD MMM');
      const today = moment().locale(`${this.$t('notifications.language')}`);
      const startAt = formatter(this.start);
      const endAt = formatter(this.end);
      const diffInDays = moment(setEndTime)
        .locale(`${this.$t('notifications.language')}`)
        .diff(today, 'days', true);
      const fullDays = Math.abs(Math.ceil(diffInDays));
      const localePath =
        diffInDays >= -1 ? 'counts.dates.day_left' : 'counts.dates.day_pass';

      return `${startAt} - ${endAt}, ${this.$tc(localePath, fullDays)}`;
    }
  }
};
</script>

<style></style>
