<template>
  <modal
    :name="modalName"
    height="auto"
    scrollable
    :width="576"
    @closed="clearForm"
  >
    <div class="p-5 flex flex-col">
      <div>
        <header class="mb-8">
          <h3 class="text-xl">
            {{ $t('agile.create.title') }}
          </h3>
          <!-- <p class="text-sm text-gray-caption">
            {{ $t('agile.create.description') }}
          </p> -->
        </header>
        <div class="start-sprint__field">
          <label>
            {{ $t('agile.create.sprint') }}
          </label>
          <p class="bg-gray-main py-2 px-4 border rounded">
            {{ name }}
          </p>
        </div>
        <div class="start-sprint__field">
          <label>
            {{ $t('agile.create.duration') }}
          </label>
          <base-select
            v-model="duration"
            :placeholder="$t('agile.create.choose')"
            placeholder-classes="text-black"
            full-width
            :options="durations"
            @change="onDurationChanged"
          />
        </div>
        <div v-if="duration != null" class="start-sprint__field">
          <label>
            {{ $t('input.start_date') }}
          </label>
          <v-date-picker
            v-model="startAt"
            v-bind="datepicker"
            :columns="$screens({ default: 1, md: 2 })"
            :max-date="endAt"
          >
            <template #default="{ inputValue, inputEvents, isDragging }">
              <input
                class="flex-grow base-input"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
        <div v-if="duration != null" class="start-sprint__field">
          <label>
            {{ $t('input.end_date') }}
          </label>
          <v-date-picker
            v-model="endAt"
            v-bind="datepicker"
            :columns="$screens({ default: 1, md: 2 })"
            :min-date="startAt"
          >
            <template #default="{ inputValue, inputEvents, isDragging }">
              <input
                class="flex-grow base-input"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
        <div class="start-sprint__field">
          <label
            >{{ $t('agile.create.goal') }}
            <small>({{ $t('agile.create.option') }})</small></label
          >
          <textarea
            v-model="goal"
            :placeholder="$t('agile.create.placeholder')"
            class="base-input"
            rows="4"
          />
        </div>
      </div>

      <layout-confirm
        :confirm-text="$t('sprint.start')"
        :cancel-text="$t('agile.create.cancel')"
        right
        :disabled="disabled"
        class="mt-8"
        @confirm="onConfirmStart"
        @cancel="toggleModal"
      />
    </div>
  </modal>
</template>

<script>
import moment from 'moment';
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';
import LayoutConfirm from '@/components/layout/layout-confirm';

const TOTAL_DURATIONS = 5;

export default {
  components: {
    VDatePicker,
    LayoutConfirm
  },
  props: {
    name: String
  },
  data() {
    return {
      modalName: 'sprint-start',
      startAt: null,
      endAt: null,
      duration: null,
      goal: '',
      datepicker: {
        attributes: [
          {
            bar: 'gray',
            key: 'today',
            dates: new Date()
          }
        ],
        locale: this.$i18n.locale,
        popover: { visibility: 'focus', transition: 'none' },
        masks: {
          input: ['DD/MM/YYYY']
        }
      }
    };
  },
  computed: {
    durations() {
      return Array.from(Array(TOTAL_DURATIONS)).map((_, i) => ({
        label:
          i === TOTAL_DURATIONS - 1
            ? this.$t('agile.create.custom')
            : this.$tc('counts.dates.week', i + 1),
        value: i
      }));
    },
    disabled() {
      return !(this.startAt && this.endAt);
    },

    startEnd() {
      return {
        startAt: this.startAt,
        endAt: this.endAt
      };
    }
  },
  watch: {
    startEnd: 'onStartEndChanged'
  },
  methods: {
    onConfirmStart() {
      const data = {
        startAt: this.startAt,
        endAt: this.endAt,
        goal: this.goal
      };
      this.$emit('start', data);
      this.toggleModal();
    },
    clearForm() {
      this.startAt = null;
      this.endAt = null;
    },
    toggleModal() {
      this.$modal.toggle(this.modalName);
    },
    onDurationChanged(value) {
      const isCustom = value === TOTAL_DURATIONS - 1;
      if (isCustom) {
        this.startAt = null;
        this.endAt = null;
        return;
      }
      const unit = 'day';
      const addedWeek = value + 1;
      const startAt = moment(this.startAt || new Date()).startOf(unit);
      const endAt = moment(startAt)
        .add(addedWeek, 'weeks')
        .endOf(unit);
      this.startAt = startAt.toDate();
      this.endAt = endAt.toDate();
    },
    onStartEndChanged({ startAt, endAt }) {
      if (!startAt || !endAt) return;
      const customDateIndex = TOTAL_DURATIONS - 1;
      const isCustomDate = n => n === 0 || !Number.isInteger(n);
      const duration = moment(endAt)
        .startOf('day')
        .diff(startAt, 'weeks', true);
      if (isCustomDate(duration)) this.duration = customDateIndex;
      else this.duration = this.durations[duration - 1].value;
    }
  }
};
</script>

<style lang="scss" scoped>
.start-sprint__field {
  @apply text-sm;

  label small {
    @apply text-gray-caption text-xs;
  }

  label + * {
    @apply mt-1;
  }

  & + & {
    @apply mt-5;
  }
  .base-input {
    @apply z-auto;
  }
}
</style>
