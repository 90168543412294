var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"name":_vm.modalName,"height":"auto","scrollable":"","width":576},on:{"closed":_vm.clearForm}},[_c('div',{staticClass:"p-5 flex flex-col"},[_c('div',[_c('header',{staticClass:"mb-8"},[_c('h3',{staticClass:"text-xl"},[_vm._v(" "+_vm._s(_vm.$t('agile.create.title'))+" ")])]),_c('div',{staticClass:"start-sprint__field"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('agile.create.sprint'))+" ")]),_c('p',{staticClass:"bg-gray-main py-2 px-4 border rounded"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),_c('div',{staticClass:"start-sprint__field"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('agile.create.duration'))+" ")]),_c('base-select',{attrs:{"placeholder":_vm.$t('agile.create.choose'),"placeholder-classes":"text-black","full-width":"","options":_vm.durations},on:{"change":_vm.onDurationChanged},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1),(_vm.duration != null)?_c('div',{staticClass:"start-sprint__field"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('input.start_date'))+" ")]),_c('v-date-picker',_vm._b({attrs:{"columns":_vm.$screens({ default: 1, md: 2 }),"max-date":_vm.endAt},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
var isDragging = ref.isDragging;
return [_c('input',_vm._g({staticClass:"flex-grow base-input",class:isDragging ? 'text-gray-600' : 'text-gray-900',domProps:{"value":inputValue}},inputEvents))]}}],null,false,129769518),model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}},'v-date-picker',_vm.datepicker,false))],1):_vm._e(),(_vm.duration != null)?_c('div',{staticClass:"start-sprint__field"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('input.end_date'))+" ")]),_c('v-date-picker',_vm._b({attrs:{"columns":_vm.$screens({ default: 1, md: 2 }),"min-date":_vm.startAt},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
var isDragging = ref.isDragging;
return [_c('input',_vm._g({staticClass:"flex-grow base-input",class:isDragging ? 'text-gray-600' : 'text-gray-900',domProps:{"value":inputValue}},inputEvents))]}}],null,false,129769518),model:{value:(_vm.endAt),callback:function ($$v) {_vm.endAt=$$v},expression:"endAt"}},'v-date-picker',_vm.datepicker,false))],1):_vm._e(),_c('div',{staticClass:"start-sprint__field"},[_c('label',[_vm._v(_vm._s(_vm.$t('agile.create.goal'))+" "),_c('small',[_vm._v("("+_vm._s(_vm.$t('agile.create.option'))+")")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.goal),expression:"goal"}],staticClass:"base-input",attrs:{"placeholder":_vm.$t('agile.create.placeholder'),"rows":"4"},domProps:{"value":(_vm.goal)},on:{"input":function($event){if($event.target.composing){ return; }_vm.goal=$event.target.value}}})])]),_c('layout-confirm',{staticClass:"mt-8",attrs:{"confirm-text":_vm.$t('sprint.start'),"cancel-text":_vm.$t('agile.create.cancel'),"right":"","disabled":_vm.disabled},on:{"confirm":_vm.onConfirmStart,"cancel":_vm.toggleModal}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }