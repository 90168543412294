<template>
  <div
    id="views-board-agile-sprint"
    ref="container"
    class="flex-1 grid grid-cols-1 grid-rows-1"
    :style="{ 'max-height': height + 'px' }"
  >
    <div v-if="sprint" class="flex">
      <div class="flex-1 flex flex-col overflow-hidden">
        <div
          class="
            px-8
            py-3
            flex
            justify-between
            lg:items-center
            flex-col
            lg:flex-row
            flex-wrap
          "
        >
          <div
            class="
              flex
              justify-between
              flex-col
              sm:flex-row
              flex-1
              lg:flex-none
            "
          >
            <div class="inline-block">
              <div class="flex items-baseline">
                <h2 class="text-2xl">{{ sprintName }}</h2>
                <h4 v-if="countTasks" class="text-gray-caption ml-2">
                  {{ $tc('counts.task', countTasks) }}
                </h4>
              </div>
              <info-work-duration
                class="text-gray-caption"
                :start="sprint.startAt"
                :end="sprint.endAt"
              />
            </div>
            <div class="inline-flex lg:hidden items-center">
              <div
                class="
                  bg-gray-200
                  rounded-full
                  px-3
                  py-1
                  flex
                  items-center
                  mr-6
                  text-sm
                  whitespace-no-wrap
                "
              >
                <base-icon icon="star" size="14" class="" />
                <span>{{ $tc('counts.story_point', sprint.points) }}</span>
              </div>
              <base-button
                color="light"
                shadowless
                capitalize
                wide="board"
                @click="toggleCompleteModal"
              >
                {{ $t('sprint.complete') }}
              </base-button>
            </div>
          </div>

          <div class="flex flex-col items-end lg:flex-row lg:items-center">
            <header-task-filters
              class="py-0"
              :counts="countTasks"
              search-right
              :search="{ label: false, width: 229 }"
              placeholder="Search task name"
              clear-right
              @loading-task="loadingTask"
            />
            <div class="hidden lg:inline-flex items-center">
              <div
                class="
                  bg-gray-200
                  rounded-full
                  px-3
                  py-1
                  flex
                  items-center
                  mr-6
                  text-sm
                  whitespace-no-wrap
                "
              >
                <base-icon icon="star" size="14" class="" />
                <span>{{ $tc('counts.story_point', $n(sprint.points)) }}</span>
              </div>
              <base-button
                v-if="isUserAdmin"
                color="success"
                shadowless
                capitalize
                wide="board"
                @click="toggleCompleteModal"
              >
                {{ $t('sprint.complete') }}
              </base-button>
            </div>
          </div>
        </div>

        <div v-if="loading" class="has-centered-item h-full">
          <base-spinner size="40" />
        </div>
        <board-column-drag-list v-else class="pt-0" is-agile>
          <template #header="{ classes, column }">
            <div :class="classes">
              <span>
                {{ column.name }}
              </span>
            </div>
          </template>
        </board-column-drag-list>
      </div>
      <router-view />
    </div>
    <div v-else class="has-centered-item">
      <base-empty
        :image="emptyImage"
        :title="$t('agile.emtyPage.activeSprint.title')"
      >
        <template #action>
          <base-button
            v-if="isUserAdmin"
            class="capitalize"
            wide="board"
            @click="handleEmptyClick"
          >
            <span class="text-base">
              {{ canStartSprint ? $t('sprint.start') : $t('sprint.add') }}
            </span>
          </base-button>
        </template>
      </base-empty>
    </div>
    <modal name="complete-sprint" height="auto" styles="overflow: visible;">
      <div v-if="sprint" class="p-5">
        <header class="text-xl mb-2">
          {{ $t('agile.boardComplete.title') }}
        </header>
        <p class="text-gray-caption text-sm mb-5">
          <span v-if="moveCounts.incomplete">
            {{ $t('agile.boardComplete.description1') }}
            <strong class="font-medium">
              {{ dictMoveOptions[moveValue].name }}.
            </strong>
            {{ $t('agile.boardComplete.description2') }}
          </span>
          <span v-else>
            {{ $t('agile.boardComplete.completed') }}
            <strong class="font-medium"> {{ sprintName }}</strong>
            {{ $t('agile.boardComplete.move') }}
          </span>
        </p>
        <ul>
          <li class="mb-5">
            <base-icon icon="check-circle" class="text-green-success" />
            <span class="mr-1">{{ $t('agile.boardComplete.complete') }}:</span>
            <span class="font-medium"
              >{{ moveCounts.complete }}
              {{ $t('agile.boardComplete.tasks') }}</span
            >
          </li>
          <li class="mb-5">
            <base-icon icon="warning-triangle" class="text-star-yellow" />
            <span class="mr-1"
              >{{ $t('agile.boardComplete.incomplete') }}:</span
            >
            <span class="font-medium"
              >{{ moveCounts.incomplete }}
              {{ $t('agile.boardComplete.tasks') }}</span
            >
          </li>
        </ul>
        <div v-if="moveCounts.incomplete">
          <label class="text-sm" for="select-sprint">
            {{ $t('agile.boardComplete.moveto') }}
          </label>
          <base-select
            id="select-sprint"
            v-model="moveValue"
            :options="moveOptions"
            full-width
          >
            <base-dropdown-item
              v-if="showCreateNextSprint"
              slot="bottom"
              keep
              class="text-blue-600 text-sm"
              @click="createNextSprintClick"
            >
              {{ $t('agile.boardComplete.createNextSprint') }}
            </base-dropdown-item>
          </base-select>
        </div>
        <layout-confirm
          :cancel-text="$t('agile.boardComplete.cancel')"
          :confirm-text="$t('agile.boardComplete.done')"
          right
          class="mt-10"
          @cancel="toggleCompleteModal"
          @confirm="confirmComplete"
        />
      </div>
    </modal>
    <modal-sprint-start
      v-if="getReadyToStartSprint"
      :name="getReadyToStartSprint.name"
      @start="handleStartSprint"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import MembersVisible from '@/mixins/MembersVisible';
import boardColumnDragList from '@/components/board-column/board-column-drag-list.vue';
import InfoWorkDuration from '@/components/info/info-work-duration.vue';
import LayoutConfirm from '@/components/layout/layout-confirm.vue';
import ModalSprintStart from '@/components/modal/modal-sprint-start.vue';
import HeaderTaskFilters from '@/components/header/header-task-filters.vue';

export default {
  mixins: [MembersVisible],
  components: {
    boardColumnDragList,
    InfoWorkDuration,
    LayoutConfirm,
    ModalSprintStart,
    HeaderTaskFilters
  },
  data() {
    return {
      emptyImage: require('@/assets/image/inactive-sprint.svg'),
      moveValue: 0,
      height: 'unset',
      loading: false
    };
  },
  provide: {
    statusbar: true
  },
  computed: {
    ...mapGetters('sprint', {
      sprints: 'getSprints',
      sprint: 'getActiveSprint',
      isSprintsEmpty: 'isSprintsEmpty',
      canStartSprint: 'canStartSprint',
      getReadyToStartSprint: 'getReadyToStartSprint'
    }),
    ...mapGetters('members', ['getUserMember']),
    boardID() {
      return parseInt(this.$route.params.id, 10);
    },
    sprintName() {
      return this.sprint.name;
    },
    countTasks() {
      return this.sprint.tasks.length;
    },
    moveOptions() {
      return this.sprints
        .filter(e => !e.isActive)
        .map(e => ({ value: e.id, label: e.name }));
    },
    dictMoveOptions() {
      return _.keyBy(this.sprints, 'id');
    },
    moveCounts() {
      if (!this.sprint) return {};
      const tasks = this.sprint.tasks;
      const complete = tasks.filter(e => e.boardColumn.status === 2).length;
      return {
        incomplete: tasks.length - complete,
        complete
      };
    },
    isUserViewer() {
      return this.$store.getters['boards/isUserViewer'];
    },
    isUserAdmin() {
      return this.$store.getters['boards/isUserAdmin'];
    },
    showCreateNextSprint() {
      const active = this.sprints.find(e => e.isActive);
      let max = { id: 0 };
      this.sprints.forEach(e => {
        if (e.id > active.id) max = e;
      });
      if (max.id > active.id) return false;
      return true;
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeRouteLeave(_to, _from, next) {
    next();
  },
  beforeDestroy() {
    this.setTaskFilters();
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    'sprint.id': {
      handler(sprintId) {
        this.setTaskFilters(sprintId);
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('tasks', ['SET_FILTERS']),
    ...mapActions('sprint', [
      'setSprintCompleted',
      'createSprint',
      'startSprint'
    ]),
    setTaskFilters(sprintId) {
      this.SET_FILTERS({ sprintId: sprintId });
    },
    async handleEmptyClick() {
      if (this.canStartSprint) {
        this.$modal.show('sprint-start');
      } else {
        if (this.isSprintsEmpty) {
          await this.createSprint({ boardID: this.boardID });
        }
        this.$router.push({ name: 'Board-Backlog' });
      }
    },
    toggleCompleteModal() {
      this.$modal.toggle('complete-sprint');
      this.moveValue = this.moveOptions[0]?.value || 0;
    },
    confirmComplete() {
      this.setSprintCompleted(this.moveValue);
      this.toggleCompleteModal();
      if (this.getUserMember.role === 100) {
        this.$router.push({ name: 'Board-Backlog' });
      }
    },
    handleStartSprint(data) {
      const id = this.getReadyToStartSprint.id;
      this.startSprint({ id, data });
    },

    onResize() {
      const { top } = this.$refs.container.getBoundingClientRect();
      this.height = window.innerHeight - top;
    },
    loadingTask(e) {
      this.loading = e;
    },
    async createNextSprintClick() {
      await this.createSprint({ boardID: this.boardID });
    }
  }
};
</script>

<style></style>
